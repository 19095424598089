import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, withCtx as _withCtx, normalizeClass as _normalizeClass, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "g-row g-row--appearance_spaced g-row--space_sm g-row--space_2xl-xl g-row--align_stretch" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "tt-button__title" }
const _hoisted_4 = {
  key: 0,
  class: "g-cell g-cols g-cols--auto order-first flex for_search"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i_arrow_down = _resolveComponent("i-arrow-down")!
  const _component_d_control_input = _resolveComponent("d-control-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.list, (field) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: field.id
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["g-cell relative", field.type == $setup.s ? 'g-cols--10 order-first for_search' : 'g-cols--4'])
        }, [
          _createVNode(_component_d_control_input, _mergeProps({
            "input-label": field.caption,
            name: field.id,
            options: field.props.items || [],
            ref_for: true,
            ref: field.id,
            type: $setup.options[field.type].type,
            placeholder: field.placeholder || ''
          }, $setup.options[field.type], {
            modelValue: $setup.form[field.id],
            "onUpdate:modelValue": ($event: any) => (($setup.form[field.id]) = $event),
            onOnChange: ($event: any) => ($setup.setValue($setup.form[field.id], field.id)),
            onSelect: ($event: any) => ($setup.setValue( null, field.id)),
            onRemove: ($event: any) => ($setup.setValue( null, field.id))
          }), {
            caret: _withCtx(({ toggle }) => [
              _createVNode(_component_i_arrow_down, { class: "multiselect-caret" })
            ]),
            default: _withCtx(() => [
              (field.type == $setup.s)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "tt-button",
                    onClick: _withModifiers(($event: any) => ($setup.setValue( null, field.id)), ["prevent"])
                  }, [
                    _createElementVNode("p", _hoisted_3, _toDisplayString($setup.$t('Search')), 1)
                  ], 8, _hoisted_2))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1040, ["input-label", "name", "options", "type", "placeholder", "modelValue", "onUpdate:modelValue", "onOnChange", "onSelect", "onRemove"])
        ], 2),
        (field.type == $setup.s)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "default")
            ]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}