import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tt-header" }
const _hoisted_2 = { class: "tt-header__page-title-wrapper tt-header__page-title-wrapper--auto" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "tt-header__element tt-header__element--narrow" }
const _hoisted_5 = { class: "tt-header__userbar-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_bar = _resolveComponent("user-bar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", {
        class: "tt-header__page-title",
        innerHTML: $setup.breadcrumbsLabels[$setup.pageTitle]
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_user_bar)
      ])
    ])
  ]))
}