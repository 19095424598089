import { App } from 'vue'
import IEye from './i-eye.vue'
import IEyeOff from './i-eye-off.vue'
import IArrowDown from './i-arrow-down.vue'
import IArrowDownLight from './i-arrow-down-light.vue'
import IProtocols from './i-protocols.vue'
import IComments from './i-comments.vue'
import IPhotoCamera from './i-photo-camera.vue'
import IDragg from './i-dragg.vue'
import IEdit from './i-edit.vue'
import IDelete from './i-delete.vue'
import IArrowLeft from "./i-arrow-left.vue";
import IArrowRight from "./i-arrow-right.vue";
import IKey from "./i-key.vue";
import IClose from "./i-close.vue";
import IDate from "./i-date.vue";
import ISearch from "./i-search.vue";
import ILoad from "./i-load.vue";
import IDropzone from "./i-dropzone.vue";
import IPdf from "./i-pdf.vue";
import ICheck from "./i-check.vue";
import ITrash from "./i-trash.vue";
import IStore from "./i-store.vue";
import IPlus from "./i-plus.vue";
import ISave from "./i-save.vue";
import IFile from "./i-file.vue";
import IConversion from "./i-conversion.vue";
import IDocsUpload from "./i-docs-upload.vue";

const register = (app: App<Element>): void => {
  app
  .component('IFile', IFile)
  .component('ISave', ISave)
  .component('IPlus', IPlus)
  .component('IDocsUpload', IDocsUpload)
  .component('IConversion', IConversion)
  .component('IArrowDownLight', IArrowDownLight)
  .component('IStore', IStore)
  .component('ITrash', ITrash)
  .component('ICheck', ICheck)
  .component('IPdf', IPdf)
  .component('ILoad', ILoad)
  .component('IDropzone', IDropzone)
  .component('ISearch', ISearch)
  .component('IDate', IDate)
  .component('IClose', IClose)
  .component('IArrowDown', IArrowDown)
  .component('IEye', IEye)
  .component('IEyeOff', IEyeOff)
  .component('IProtocols', IProtocols)
  .component('IComments', IComments)
  .component('IPhotoCamera', IPhotoCamera)
  .component('IDragg', IDragg)
  .component('IEdit', IEdit)
  .component('IDelete', IDelete)
  .component('IArrowRight', IArrowRight)
  .component('IArrowLeft', IArrowLeft)
  .component('IKey', IKey)
}
export default {
  register,
}
