import { RouterView, RouteRecordRaw } from 'vue-router';
import { DefineComponent } from 'vue';

const mergeOptions = (
  result: RouteRecordRaw,
  options: null | Record<string, unknown> = null
): RouteRecordRaw => {
  return options !== null ? Object.assign(result, options) : result;
};

/*
/   Simple view for RouteRecordRaw
/   https://router.vuejs.org/api/#Type-Aliases-RouteRecordRaw
*/
export function route(
  path: string,
  component: (() => Promise<typeof import('*.vue')>) | DefineComponent | never | any,
  name: string | symbol | undefined,
  options: null | Record<string, unknown> = null
): RouteRecordRaw {
  return mergeOptions({ path, component, name }, options);
}

/*
/   Return wrapped route in RouterView-component
/   you unnecessary use RouterView
*/
export function prefixRoutes(
  path: string,
  children: RouteRecordRaw[],
  options: null | Record<string, unknown> = null
): RouteRecordRaw {
  return mergeOptions(
    { path, component: RouterView, children },
    options
  );
}
