import type { App } from 'vue';
import {
  Router,
  RouteRecordRaw,
  createWebHistory,
  createRouter,
  RouteLocation,
  RouteLocationNormalizedLoaded,
} from 'vue-router';
import { Pinia } from 'pinia';
import createInitialRoutes from '@/router/routes';
import { accessGuard } from '@/packages/vue-router-permissions'

export type Options = {
  app: App<Element>,
  createInstance: typeof createRouter,
  createWebHistory: typeof createWebHistory,
  pinia: Pinia,
};

declare module 'vue-router' {
  interface RouteMeta {
    accessConfig?: {
      checker: (...arg: any[]) => boolean
    }
  }
}

export function createRoutes(options: Options): RouteRecordRaw[] {
  return [
    ...createInitialRoutes(options),
  ];
}

type ScrollPositionNormalized = {
  behavior?: ScrollOptions['behavior'],
  left: number,
  top: number,
};

export function scrollBehavior(
  to: RouteLocation,
  from: RouteLocationNormalizedLoaded,
  savedPosition: null | ScrollPositionNormalized
): false | void | { top: number, left: number } | { el: string | Element } {
  if (savedPosition) {
    return savedPosition;
  }
  return {
    top: window.pageYOffset,
    left: window.pageXOffset,
  };
}

export default function createAppRouter(options: Options): Router {
  const { createInstance, createWebHistory } = options;
  const router = createInstance({
    history: createWebHistory('/'),
    scrollBehavior,
    routes: createRoutes(options),
  });

  router.beforeEach(accessGuard(options));

  return router;
}
