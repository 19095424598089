import { App } from 'vue'
import Sidebar from './Sidebar.vue'
import MainHeader from './MainHeader.vue'
import Checker from './Checker.vue'
import FiltersView from './FiltersView.vue'
import UserBar from './UserBar.vue'

const register = (app: App<Element>): void => {
  app
  .component('Sidebar', Sidebar)
  .component('UserBar', UserBar)
  .component('MainHeader', MainHeader)
  .component('Checker', Checker)
  .component('FiltersView', FiltersView)
}

export default {
  register,
}
