import type { RouteRecordRaw } from 'vue-router';
import { route } from '@/router/utils'

const accessIsDenied = () => import('./403.vue')

// NAMES
export const ACCESS_DENIED_PAGE_NAME = 'cabinet:access-is-denied'

// URLs
export const ACCESS_DENIED_PAGE_URL = ''

export function createRoutes(): RouteRecordRaw[] {
  return [
    route(ACCESS_DENIED_PAGE_URL, accessIsDenied, ACCESS_DENIED_PAGE_NAME, {
      meta: {
        breadcrumb: 'access-denied',
      }
    }),
  ]
}
