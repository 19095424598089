import { RouteRecordRaw } from 'vue-router';
import { route } from '@/router/utils'
// import { allPermissions } from '@access/index'
// import { accessPermission } from '@access/permissions'

// const {
//   STAFF: {
//     TRANSACTION: {
//       VIEW_LIST
//     },
//   },
// } = allPermissions

const protocolsList = () => import('./views/List.vue')
const protocolCreat = () => import('./views/Create.vue')

export const PROTOCOLS_LIST_PAGE_NAME = 'cabinet:protocols-list'
export const PROTOCOL_EDIT_PAGE_NAME = 'cabinet:protocol-edit'
export const PROTOCOL_CREATE_PAGE_NAME = 'cabinet:protocol-create'

// URLs
export const PROTOCOLS_LIST_PAGE_URL = ''
export const PROTOCOL_EDIT_PAGE_URL = 'edit/:id?/'
export const PROTOCOL_CREATE_PAGE_URL = 'create/'

export function createRoutes(): RouteRecordRaw[] {
  return [
    route(PROTOCOLS_LIST_PAGE_URL, protocolsList, PROTOCOLS_LIST_PAGE_NAME, {
      meta: {
        breadcrumb: 'protocols',
        // ...accessPermission(VIEW_LIST)
      }
    }),
    route(PROTOCOL_EDIT_PAGE_URL, protocolCreat, PROTOCOL_EDIT_PAGE_NAME, {
      meta: {
        breadcrumb: 'protocols',
        isNew: false
        // ...accessPermission(VIEW_LIST)
      }
    }),
    route(PROTOCOL_CREATE_PAGE_URL, protocolCreat, PROTOCOL_CREATE_PAGE_NAME, {
      meta: {
        breadcrumb: 'protocols',
        isNew: true
        // ...accessPermission(VIEW_LIST)
      }
    }),
  ]
}
