import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "ds-panel" }
const _hoisted_2 = { class: "ds-panel__element" }
const _hoisted_3 = { class: "main-title main-title--variant_modal" }
const _hoisted_4 = { class: "ds-panel__element" }
const _hoisted_5 = {
  key: 0,
  class: "g-cell g-cols g-cols--12"
}
const _hoisted_6 = { class: "tt-card tt-card--appearance_accent" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = {
  key: 1,
  class: "g-cell g-cols g-cols--11 g-cols--10-sm"
}
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_11 = { class: "g-row g-row--justify_around g-row--appearance_spaced" }
const _hoisted_12 = { class: "g-cell g-cols g-cols--6" }
const _hoisted_13 = { class: "tt-button__title" }
const _hoisted_14 = { class: "g-cell g-cols g-cols--6" }
const _hoisted_15 = ["styling"]
const _hoisted_16 = { class: "tt-button__title" }
const _hoisted_17 = {
  key: 0,
  class: "ds-panel__element ds-panel__element--offset_top"
}
const _hoisted_18 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString($props.question), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      ($props.description)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            ($props.danger)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("p", {
                      class: "ds-caption ds-caption--color_danger ds-caption--size_sm ds-caption--size_md-lg",
                      innerHTML: $props.description
                    }, null, 8, _hoisted_7)
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createElementVNode("p", {
                    class: "tt-space_mb tt-space_mb--3 tt-space_mb--4-xl ds-caption ds-caption--color_1 ds-caption--size_sm ds-caption--size_lg-lg ds-caption--appearance_center",
                    innerHTML: $props.description
                  }, null, 8, _hoisted_9)
                ]))
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("button", {
            class: "tt-button tt-button--trans",
            size: ['lg'],
            view: "full",
            type: "button",
            variant: ['outlined'],
            styling: "dark",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('finish', false)), ["prevent"]))
          }, [
            _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t('Cancel')), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("button", {
            class: "tt-button",
            size: ['lg'],
            view: "full",
            type: "button",
            styling: $props.danger ? 'danger' : 'primary',
            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit('finish', true)), ["prevent"]))
          }, [
            _createElementVNode("span", _hoisted_16, _toDisplayString($props.approvalText ? $props.approvalText : _ctx.$t('Confirm')), 1)
          ], 8, _hoisted_15)
        ])
      ])
    ]),
    ($props.danger)
      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
          _createElementVNode("p", {
            class: "ds-caption ds-caption--size_3xs",
            innerHTML: _ctx.$t('This action cannot be undone. Confirm deletion by clicking <strong>Confirm</strong>, or press <strong>Cancel</strong> to leave the data unchanged')
          }, null, 8, _hoisted_18)
        ]))
      : _createCommentVNode("", true)
  ]))
}