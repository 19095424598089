import { RouteRecordRaw } from 'vue-router';
import { route } from '@/router/utils'
// import { allPermissions } from '@access/index'
// import { accessPermission } from '@access/permissions'

// const {
//   STAFF: {
//     TRANSACTION: {
//       VIEW_LIST
//     },
//   },
// } = allPermissions

const trashAttachmentsList = () => import('../attachments/views/List.vue')

export const TRASH_ATTACHMENTS_LIST_PAGE_NAME = 'cabinet:trash-attachments-list'

// URLs
export const TRASH_ATTACHMENTS_LIST_PAGE_URL = ''

export function createRoutes(): RouteRecordRaw[] {
  return [
    route(TRASH_ATTACHMENTS_LIST_PAGE_URL, trashAttachmentsList, TRASH_ATTACHMENTS_LIST_PAGE_NAME, {
      meta: {
        breadcrumb: 'trash-pdf',
        // ...accessPermission(VIEW_LIST)
      }
    }),
  ]
}
