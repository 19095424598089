import { App } from 'vue'

import Validation from './validation/plugin'
import Uploading from './uploading/plugin'
// import Datepicker from './vue3-datepicker'
import FlatPickr from './vue3-flatpickr'
// import PbField from './page-loader-field'
// import PbProvider from './page-loader-field'
// import VueAgile from './vue3-vue-agile'
import VueFinalModal from './vue-final-modal'
// import Auth from './auth'
// import Company from './company'
// import { VueMaskDirective } from 'v-mask'
// import VueSlider from './vue-slider-component'
// import Tippy from './vue-tippy'
// import MazPhoneNumberInput from './vue-maz-phone-number-input'
// import VueSocialSharing from './vue-social-sharing'
// import VueClipboard from './vue-clipboard'
// import VueEasyLightbox from './vue-easy-lightbox'

export default {
  install(app: App<Element>) {
    app
      // .directive('mask', VueMaskDirective)
      // .use(PbField)
      // .use(PbProvider)
      // .use(VueAgile)
      .use(VueFinalModal)
      .use(Validation)
      .use(FlatPickr)
      .use(Uploading)
      // .use(Datepicker)
      // .use(Auth)
      // .use(Company)
      // .use(VueSlider)
      // .use(Tippy)
      // .use(MazPhoneNumberInput)
      // .use(VueSocialSharing)
      // .use(VueClipboard)
      // .use(VueEasyLightbox)
  }
}
