import {
  createSender,
} from '@/resource/resource'

import {
  prefixAPI,
  API_BASE_URL,
} from '@/resource/api'

const AUTH_MODEL = 'auth'
const TWO_FACTOR = '2factor'
const PASSWORD_MODEL = 'auth/password'
// const AUTHENTICATE_LINK = prefixAPI('/login/', MODEL)

const TWO_FACTOR_CONFIRM = prefixAPI('/confirm/', TWO_FACTOR)
const TWO_FACTOR_REQUEST = prefixAPI('/request-confirmation/', TWO_FACTOR)

const LOGIN_LINK = prefixAPI('/login/', AUTH_MODEL)
const LOGOUT_LINK = prefixAPI('/logout/', AUTH_MODEL)
const PASSWORD_RESET_LINK = prefixAPI('/reset/', PASSWORD_MODEL)

export const twoFactorConfirm = createSender(TWO_FACTOR_CONFIRM)
export const twoFactorRequest = createSender(TWO_FACTOR_REQUEST)

export const loginResource = createSender(LOGIN_LINK)
export const logoutResource = createSender(LOGOUT_LINK)

export const passwordReset = createSender(PASSWORD_RESET_LINK)

