/* eslint-disable */
import { partialRight } from 'ramda'
import {
  prefixAPI,
} from '@/resource/api'
import {
  baseResource as superResource,
  jsonRequestMiddleware,
} from '@aspectus/resource'

import {
  xCSRFMiddleware,
  multipartRequestMiddleware,
  jsonTransformer,
  defaultDataTransformer,
  raiseHttpErrorTransformer,
  superagentJsonTransformer,
} from '@/resource/resource-commons/main.js'

import {
  RESPONSE_TYPE_MIDDLEWERE
} from '@/resource/middleware'

import makeTemplateGetter from '@aspectus/resource-template-url-getter';

import fetcher from '@aspectus/superagent-fetch';
import {
  HEADERS_MIDDLEWERE,
} from '@/resource/middleware'

export const baseResource = superResource
  .middleware(xCSRFMiddleware)
  .middleware(HEADERS_MIDDLEWERE)
  .transform(raiseHttpErrorTransformer)
  .config('mode', 'cors')

export const receiveResource = baseResource
  .transform(jsonTransformer)
  .transform(defaultDataTransformer)

export const sendResource = baseResource
  .middleware(jsonRequestMiddleware)
  .transform(jsonTransformer)
  .config('method', 'POST')

export const simpleSendResource = baseResource
  .config('method', 'POST')

export const updateResource = sendResource
  .config('method', 'PATCH')

export const replaceResource = sendResource
  .config('method', 'PUT')

export const destroyResource = baseResource
  .config('method', 'DELETE')

export const optionsResource = receiveResource
  .config('method', 'OPTIONS')

export const sendFileResource = baseResource
  .fetcher(fetcher)
  .middleware(multipartRequestMiddleware)
  .transform(superagentJsonTransformer)
  .transform(defaultDataTransformer)
  .config('method', 'POST');

export const updateWithFile = sendFileResource
  .config('method', 'PATCH');

export const simpleGetResource = baseResource
  .config('method', 'GET')
  .middleware(RESPONSE_TYPE_MIDDLEWERE)

export const createResource = (template, base = baseResource) => {
  return base.middleware(HEADERS_MIDDLEWERE).url(makeTemplateGetter(template))
}
export const createReceiver = partialRight(createResource, [receiveResource])
export const createSender = partialRight(createResource, [sendResource])
export const createUpdater = partialRight(createResource, [updateResource])
export const createReplacer = partialRight(createResource, [replaceResource])
export const createSimpleSender = partialRight(createResource, [simpleSendResource])
export const createFileSender = partialRight(createResource, [sendFileResource])
export const createUpdaterWithFile = partialRight(createResource, [updateWithFile])
export const createOptionsGetter = partialRight(createResource, [optionsResource])
export const createRemover = partialRight(createResource, [destroyResource])
export const createSimpleGetter = partialRight(createResource, [simpleGetResource])

export const r = (tpl, model = '', f = createReceiver) =>
  f(prefixAPI(tpl, model))