import { RouteRecordRaw } from 'vue-router';
import { route } from '@/router/utils'
// import { allPermissions } from '@access/index'
// import { accessPermission } from '@access/permissions'

// const {
//   STAFF: {
//     TRANSACTION: {
//       VIEW_LIST
//     },
//   },
// } = allPermissions

const sourcesList = () => import('./views/List.vue')
const sourceCreate = () => import('./views/Create.vue')

export const SOURCES_LIST_PAGE_NAME = 'cabinet:sources-list'
export const SOURCE_EDIT_PAGE_NAME = 'cabinet:source-edit'
export const SOURCE_CREATE_PAGE_NAME = 'cabinet:source-create'

// URLs
export const SOURCES_LIST_PAGE_URL = ''
export const SOURCE_EDIT_PAGE_URL = 'edit/:id?/'
export const SOURCE_CREATE_PAGE_URL = 'create/'

export function createRoutes(): RouteRecordRaw[] {
  return [
    route(SOURCES_LIST_PAGE_URL, sourcesList, SOURCES_LIST_PAGE_NAME, {
      meta: {
        breadcrumb: 'sources',
        // ...accessPermission(VIEW_LIST)
      }
    }),
    route(SOURCE_EDIT_PAGE_URL, sourceCreate, SOURCE_EDIT_PAGE_NAME, {
      meta: {
        breadcrumb: 'sources',
        isNew: false
        // ...accessPermission(VIEW_LIST)
      }
    }),
    route(SOURCE_CREATE_PAGE_URL, sourceCreate, SOURCE_CREATE_PAGE_NAME, {
      meta: {
        breadcrumb: 'sources',
        isNew: true
        // ...accessPermission(VIEW_LIST)
      }
    }),
  ]
}
