<template>
<svg width="51" height="64" viewBox="0 0 51 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_1153_10597)">
<g clip-path="url(#clip0_1153_10597)">
<path d="M39.0599 0H4.4111V60H49V8.01844L39.0599 0Z" fill="white"/>
<path d="M31.1845 9.42028H2V21.5446H31.1845V9.42028Z" fill="#D31111"/>
<path d="M9.04163 18.3108V12.6545H10.9038C11.6095 12.6545 12.0695 12.6828 12.2837 12.7394C12.6131 12.8243 12.8888 13.0089 13.1109 13.2931C13.333 13.5774 13.4441 13.9446 13.4441 14.3947C13.4441 14.742 13.3801 15.0339 13.252 15.2706C13.124 15.5072 12.9612 15.693 12.7639 15.8281C12.5666 15.9631 12.366 16.0526 12.1622 16.0963C11.8852 16.1504 11.484 16.1774 10.9586 16.1774H10.202V18.3111H9.0415L9.04163 18.3108ZM10.2021 13.6113V15.2164H10.8372C11.2945 15.2164 11.6004 15.1868 11.7545 15.1277C11.9087 15.0686 12.0296 14.976 12.1171 14.8499C12.2047 14.7239 12.2485 14.5773 12.2485 14.4101C12.2485 14.2043 12.1871 14.0345 12.0643 13.9007C11.9414 13.7671 11.786 13.6833 11.5978 13.6499C11.4593 13.6242 11.1809 13.6113 10.7627 13.6113H10.2021Z" fill="white"/>
<path d="M14.3929 12.6542H16.5138C16.992 12.6542 17.3566 12.6903 17.6076 12.7623C17.9447 12.8601 18.2334 13.0337 18.474 13.2831C18.7145 13.5326 18.8975 13.8381 19.0228 14.1995C19.1483 14.5609 19.2109 15.0066 19.2109 15.5364C19.2109 16.002 19.1521 16.4032 19.0345 16.7402C18.8907 17.1518 18.6856 17.4849 18.419 17.7395C18.2177 17.9324 17.9459 18.0829 17.6035 18.1909C17.3474 18.2706 17.005 18.3105 16.5764 18.3105H14.3927V12.6542H14.3929ZM15.5534 13.611V17.3575H16.4199C16.7439 17.3575 16.9779 17.3396 17.1216 17.3035C17.3097 17.2572 17.4658 17.1788 17.5901 17.0681C17.7142 16.9576 17.8155 16.7756 17.8938 16.5222C17.9723 16.2689 18.0114 15.9236 18.0114 15.4862C18.0114 15.0489 17.9723 14.7133 17.8938 14.4792C17.8154 14.2452 17.7057 14.0624 17.5646 13.9313C17.4235 13.8001 17.2445 13.7114 17.0275 13.6651C16.8655 13.6291 16.5479 13.611 16.0749 13.611H15.5534Z" fill="white"/>
<path d="M20.2029 18.3106V12.6543H24.1428V13.6111H21.3632V14.95H23.7625V15.9068H21.3632V18.3106H20.2029Z" fill="white"/>
<path d="M46.6241 24.9957H6.78699V34.5996H46.6241V24.9957Z" fill="#D6D6D6"/>
<path d="M46.6241 36.3817H6.78699V57.1735H46.6241V36.3817Z" fill="#D6D6D6"/>
</g>
</g>
<defs>
<filter id="filter0_d_1153_10597" x="0" y="0" width="51" height="64" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="2"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1153_10597"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1153_10597" result="shape"/>
</filter>
<clipPath id="clip0_1153_10597">
<rect width="47" height="60" fill="white" transform="translate(2)"/>
</clipPath>
</defs>
</svg>
</template>