import { RouteRecordRaw } from 'vue-router';
import { route } from '@/router/utils'
// import { allPermissions } from '@access/index'
// import { accessPermission } from '@access/permissions'

// const {
//   STAFF: {
//     TRANSACTION: {
//       VIEW_LIST
//     },
//   },
// } = allPermissions

const tagsTrashList = () => import('../tags/views/List.vue')
// const tagCreate = () => import('./views/Create.vue')

export const TAGS_TRASH_LIST_PAGE_NAME = 'cabinet:trash-tags-list'
// export const TAG_EDIT_PAGE_NAME = 'cabinet:tag-edit'
// export const TAG_CREATE_PAGE_NAME = 'cabinet:tag-create'

// URLs
export const TAGS_TRASH_LIST_PAGE_URL = ''
// export const TAG_EDIT_PAGE_URL = 'edit/:id?/'
// export const TAG_CREATE_PAGE_URL = 'create/'

export function createRoutes(): RouteRecordRaw[] {
  return [
    route(TAGS_TRASH_LIST_PAGE_URL, tagsTrashList, TAGS_TRASH_LIST_PAGE_NAME, {
      meta: {
        breadcrumb: 'trash-tags',
        // ...accessPermission(VIEW_LIST)
      }
    }),
    // route(TAG_EDIT_PAGE_URL, tagCreate, TAG_EDIT_PAGE_NAME, {
    //   meta: {
    //     breadcrumb: 'sources',
    //     isNew: false
    //     // ...accessPermission(VIEW_LIST)
    //   }
    // }),
    // route(TAG_CREATE_PAGE_URL, tagCreate, TAG_CREATE_PAGE_NAME, {
    //   meta: {
    //     breadcrumb: 'sources',
    //     isNew: true
    //     // ...accessPermission(VIEW_LIST)
    //   }
    // }),
  ]
}
