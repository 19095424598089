import { App } from 'vue'
import Elements from './elements'
import Control from './control'
import Forms from './forms'
import Routing from './routing'
import Pages from './pages'
import Ui from './ui'
import I from './i'

export default {
  install(app: App<Element>): void {
    Ui.register(app)
    Elements.register(app)
    // General.register(app)
    Routing.register(app)
    Control.register(app)
    Forms.register(app)
    Pages.register(app)
    // UiElements.register(app)
    I.register(app)
  }
}
