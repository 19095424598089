import { createApp, App } from "vue"
import * as GVue from 'vue'
import { createPinia } from "pinia"
import { createRouter, createWebHistory } from "vue-router"
import createAppRouter from "@/router"
import install from '@/install'
import i18n from "./i18n"

import 'wc-page-builder/builder.css'
import 'wc-page-builder/prime.css'
import 'wc-page-builder/container-grid.css'
import 'wc-page-builder/container-grid.css'
import 'wc-page-builder/themes/default.css'

import 'vue-final-modal/style.css'

import PageBuilder from 'wc-page-builder/builder'
import * as GPageBuilder from 'wc-page-builder/builder'
import PageBuilderPrime, { primeRenderers } from 'wc-page-builder/prime'

import PrimeVue from 'primevue/config'
import { definePreset } from '@primevue/themes'
import Aura from '@primevue/themes/aura'

// This global vars are required for accordion plugin to work.
(window as any).Vue = GVue;
(window as any).WcPageBuilder = GPageBuilder;

import Components from "@/components"
import Modules from "@/modules"

type CreateApplication = {
  createApp: typeof createApp
  createRouter: typeof createRouter
  createWebHistory: typeof createWebHistory
  createPinia: typeof createPinia
}

export function createApplication({
  createApp,
  createRouter: createRouterInstance,
  createWebHistory,
  createPinia,
}: CreateApplication): App {
  const app = createApp({})

  app.config.compilerOptions.delimiters = ['[[', ']]']

  const pinia = createPinia()

  const router = createAppRouter({
    app,
    createInstance: createRouterInstance,
    createWebHistory,
    pinia,
  })

  app
    .use(install)
    .use(pinia)
    .use(i18n)
    .use(router)
    .use(Modules)
    .use(Components)
    .use(PrimeVue, { theme: { preset: Aura } })
    .use(PageBuilder, {
      renderers: primeRenderers,
    })
    .use(PageBuilderPrime)
    .mount('#app')

  return app
}
