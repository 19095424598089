/* eslint-disable */

import { path, curry } from 'ramda'
import { checkAuth } from '@access/user'
import { Or } from '@aspectus/permissions';
import { accessMeta } from '@/packages/vue-router-permissions';
import { userPermissions } from './index'

const userStateGetter = path(['state', 'value', 'user'])

export function checkUser(checker, config = {}, ...args) {
  return checkAuth(
    checker, Object.assign({ getter: userStateGetter }, config), ...args
  )
}

export const isPermitted = curry(
  (permission, user) => !!userPermissions.find(e => e == permission)
);

export const toHasSimplePermission = curry(
  (permission) => !!userPermissions.find((e) => e == permission)
);

export const checkPermissions = perms => {
  if (Array.isArray(perms)) {
    return perms.every(perm => toHasSimplePermission(perm))
  }
  return toHasSimplePermission(perms)
}

const initialRoute = { name: 'cabinet:access-is-denied' }

export const toHasOwnPermission = permission => checkUser(isPermitted(permission))

export const toHasPermission = permission => new Or(
  toHasOwnPermission(permission),
);

export const accessPermission = (permissions, options) =>
  accessMeta(
    toHasPermission(permissions), initialRoute, options
  )

export const isLp = window.IS_LP
export const isAdmin = window.IS_ADMIN
export const isSuperUser = window.IS_SUPERUSER
export const recaptcha_site_key = window?.recaptcha_site_key



