import { RouteRecordRaw } from 'vue-router';
import { route } from '@/router/utils'
// import { allPermissions } from '@access/index'
// import { accessPermission } from '@access/permissions'

// const {
//   STAFF: {
//     TRANSACTION: {
//       VIEW_LIST
//     },
//   },
// } = allPermissions

const questionsList = () => import('./views/List.vue')
const questionView = () => import('./views/View.vue')

export const QUESTIONS_LIST_PAGE_NAME = 'cabinet:questions-list'
export const QUESTION_VIEW_PAGE_NAME = 'cabinet:source-view'

// URLs
export const QUESTIONS_LIST_PAGE_URL = ''
export const QUESTION_VIEW_PAGE_URL = 'view/:id?/'

export function createRoutes(): RouteRecordRaw[] {
  return [
    route(QUESTIONS_LIST_PAGE_URL, questionsList, QUESTIONS_LIST_PAGE_NAME, {
      meta: {
        breadcrumb: 'questions',
        // ...accessPermission(VIEW_LIST)
      }
    }),
    route(QUESTION_VIEW_PAGE_URL, questionView, QUESTION_VIEW_PAGE_NAME, {
      meta: {
        breadcrumb: 'questions',
        // ...accessPermission(VIEW_LIST)
      }
    }),
  ]
}
