import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, withCtx as _withCtx, withModifiers as _withModifiers, Transition as _Transition } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "ds-avatar ds-avatar--appearance_rounded ds-avatar--size_md" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "ds-avatar__label"
}
const _hoisted_5 = { class: "tt-userbar__name" }
const _hoisted_6 = {
  key: 0,
  class: "tt-userbar__content"
}
const _hoisted_7 = { class: "tt-userbar__menu" }
const _hoisted_8 = { class: "tt-userbar__menu-element disabled" }
const _hoisted_9 = { class: "tt-userbar__icon-wrapper" }
const _hoisted_10 = { class: "tt-userbar__icon" }
const _hoisted_11 = { class: "tt-userbar__text" }
const _hoisted_12 = { class: "tt-userbar__menu-element" }
const _hoisted_13 = { class: "tt-userbar__icon-wrapper" }
const _hoisted_14 = { class: "tt-userbar__icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i_arrow_down = _resolveComponent("i-arrow-down")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ui_click_outside = _resolveComponent("ui-click-outside")!
  const _component_sidebar_trigger = _resolveComponent("sidebar-trigger")!

  return (_openBlock(), _createBlock(_component_sidebar_trigger, { "input-ref": _ctx.$emit }, {
    default: _withCtx(({ sidebarIsOpen, sidebarToggle, sidebarClose }) => [
      _createVNode(_component_ui_click_outside, { do: sidebarClose }, {
        default: _withCtx(({ ref }) => [
          _createElementVNode("div", {
            class: "userbar-click-wrapper",
            ref: ref
          }, [
            _createElementVNode("div", null, [
              _createElementVNode("div", {
                class: "tt-userbar",
                onClick: _withModifiers(sidebarToggle, ["prevent"])
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["tt-userbar__avatar", {'is-active': sidebarIsOpen}])
                }, [
                  _createElementVNode("div", _hoisted_2, [
                    ($setup.profile?.avatar && $setup.profile?.avatar.length)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          class: "ds-avatar__image",
                          src: $setup.profile?.avatar
                        }, null, 8, _hoisted_3))
                      : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString($setup.profile?.firstName? $setup.profile?.firstName[0] : '?'), 1))
                  ])
                ], 2),
                _createElementVNode("p", _hoisted_5, _toDisplayString(`${$setup.profile?.firstName} ${$setup.profile?.lastName}`), 1),
                _createElementVNode("div", {
                  class: _normalizeClass(["tt-userbar__arrow", {'is-active': sidebarIsOpen}])
                }, [
                  _createVNode(_component_i_arrow_down)
                ], 2),
                _createVNode(_Transition, { name: "fade" }, {
                  default: _withCtx(() => [
                    sidebarIsOpen
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createElementVNode("div", _hoisted_7, [
                            _createVNode(_component_router_link, {
                              class: "ds-link ds-link--userbar disabled",
                              to: {name: _ctx.PROFILE_PAGE_NAME}
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_8, [
                                  _createElementVNode("div", _hoisted_9, [
                                    _createElementVNode("div", _hoisted_10, [
                                      (_openBlock(), _createBlock(_resolveDynamicComponent('i-side-profile'), { class: "button_img" }))
                                    ])
                                  ]),
                                  _createElementVNode("div", _hoisted_11, [
                                    _createElementVNode("span", null, _toDisplayString($setup.$t('Personal cabinet')), 1)
                                  ])
                                ])
                              ]),
                              _: 1
                            }, 8, ["to"]),
                            _createElementVNode("div", _hoisted_12, [
                              _createElementVNode("div", _hoisted_13, [
                                _createElementVNode("div", _hoisted_14, [
                                  (_openBlock(), _createBlock(_resolveDynamicComponent('i-logout'), { class: "button_img" }))
                                ])
                              ]),
                              _createElementVNode("div", {
                                class: "tt-userbar__text",
                                onClick: _withModifiers($setup.logout, ["prevent"])
                              }, [
                                _createElementVNode("span", null, _toDisplayString($setup.$t('Log Out')), 1)
                              ])
                            ])
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1024)
              ], 8, _hoisted_1)
            ])
          ], 512)
        ]),
        _: 2
      }, 1032, ["do"])
    ]),
    _: 1
  }, 8, ["input-ref"]))
}