import { RouteRecordRaw } from 'vue-router';
import { route } from '@/router/utils'
// import { allPermissions } from '@access/index'
// import { accessPermission } from '@access/permissions'

// const {
//   STAFF: {
//     TRANSACTION: {
//       VIEW_LIST
//     },
//   },
// } = allPermissions

const attachmentsList = () => import('./views/List.vue')

export const ATTACHMENTS_LIST_PAGE_NAME = 'cabinet:attachments-list'

// URLs
export const ATTACHMENTS_LIST_PAGE_URL = ''

export function createRoutes(): RouteRecordRaw[] {
  return [
    route(ATTACHMENTS_LIST_PAGE_URL, attachmentsList, ATTACHMENTS_LIST_PAGE_NAME, {
      meta: {
        breadcrumb: 'pdf',
        // ...accessPermission(VIEW_LIST)
      }
    }),
  ]
}
