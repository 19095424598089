<template>
<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_34_3254)">
<path d="M17.125 24.125C20.991 24.125 24.125 20.991 24.125 17.125C24.125 13.259 20.991 10.125 17.125 10.125C13.259 10.125 10.125 13.259 10.125 17.125C10.125 20.991 13.259 24.125 17.125 24.125Z" stroke="#3A454D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M25.8751 25.8749L22.0688 22.0686" stroke="#3A454D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_34_3254">
<rect x="0.5" y="0.5" width="35" height="35" rx="17.5" fill="white"/>
</clipPath>
</defs>
</svg>
</template>