import { App } from 'vue'
import { accessMeta } from '@/packages/vue-router-permissions'
import { route, prefixRoutes } from '@/router/utils'
import { RouteRecordRaw } from 'vue-router'
import { createRoutes as createProtocolsRoutes, PROTOCOLS_LIST_PAGE_NAME } from './modules/protocols/router'
import { createRoutes as createProtocolsTrashRoutes } from './modules/trash-protocols/router'
import { createRoutes as createPageNotFoundRoutes, PAGE_NOT_FOUND_PAGE_NAME } from './modules/404/router'
import { createRoutes as createAccessDeniedRoutes } from './modules/403/router'
import { createRoutes as createTagsRoutes } from './modules/tags/router'
import { createRoutes as createTagsTrashRoutes } from './modules/trash-tags/router'
import { createRoutes as createSourcesRoutes } from './modules/sources/router'
import { createRoutes as createQuestionsRoutes } from './modules/questions/router'
import { createRoutes as createAttachmentsRoutes } from './modules/attachments/router'
import { createRoutes as createTrashAttachmentsRoutes } from './modules/trash-attachments/router'
import { allPermissions } from '@access/index'
import { accessPermission, } from '@access/permissions'
// const textPage = () => import('./modules/text-page/text-page.vue')
const Cabinet = () => import('./views/Cabinet.vue')

const {
  STAFF: {
    PROTOCOL,
    PROTOCOL_SOURCE,
    TAG,
    PROTOCOL_ATTACHMENT,
    PROTOCOL_QUESTION,
    TRASH: { 
      VIEW_PROTOCOL: VIEW_PROTOCOL_TRASH,
      VIEW_PROTOCOL_ATTACHMENT: VIEW_PROTOCOL_ATTACHMENT_TRASH,
      VIEW_TAG: VIEW_TAG_TRASH,
      VIEW_USER: VIEW_USER_TRASH
    },
  },
} = allPermissions

export const BASE_PAGE_NAME = 'cabinet:page'
export const BASE_PAGE_URL = '/cabinet/'

export function createRoutes(): RouteRecordRaw[]  {
  return [
    route(BASE_PAGE_URL, Cabinet, BASE_PAGE_NAME, {
      children: [
        prefixRoutes('protocols/list/', createProtocolsRoutes(),
          { meta: { ...accessPermission(PROTOCOL) } }
        ),
        prefixRoutes('deleted-protocols/list/', createProtocolsTrashRoutes(),
          { meta: { ...accessPermission(VIEW_PROTOCOL_TRASH), trash: true } }
        ),
        prefixRoutes('sources/list/', createSourcesRoutes(), 
          { meta: { ...accessPermission(PROTOCOL_SOURCE) } }
        ),
        prefixRoutes('questions/list/', createQuestionsRoutes(), 
        { meta: { ...accessPermission(PROTOCOL_QUESTION) } }
      ),
        prefixRoutes('pdf/list/', createAttachmentsRoutes(),
          { meta: { ...accessPermission(PROTOCOL_ATTACHMENT) } }
        ),
        prefixRoutes('deleted-pdf/list/', createTrashAttachmentsRoutes(),
          { meta: { ...accessPermission(VIEW_PROTOCOL_ATTACHMENT_TRASH), trash: true } }
        ),
        prefixRoutes('tags/list/', createTagsRoutes(), 
          { meta: { ...accessPermission(TAG) } }
        ),
        prefixRoutes('deleted-tags/list/', createTagsTrashRoutes(), 
          { meta: { ...accessPermission(VIEW_TAG_TRASH), trash: true } }
        ),
        // prefixRoutes('support/', createSupportRoutes(), { meta: { ...accessPermission(FEEDBACK_CREATE) } }),
        prefixRoutes('not-found/', createPageNotFoundRoutes()),
        prefixRoutes('forbidden/', createAccessDeniedRoutes()),
        {
          path: '/cabinet/:pathMatch(.*)*',
          redirect: { name: PAGE_NOT_FOUND_PAGE_NAME }
        },
        // route('info/:slug/', textPage, 'text-page', {}),
      ],
      redirect: { name: PROTOCOLS_LIST_PAGE_NAME },
    }),
  ]
}
