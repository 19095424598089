import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-control-input__description"
}
const _hoisted_2 = ["type"]
const _hoisted_3 = { class: "input-eye-icon" }
const _hoisted_4 = { class: "input-key-icon" }
const _hoisted_5 = {
  key: 2,
  class: "input-icon-element"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i_key = _resolveComponent("i-key")!
  const _component_i_search = _resolveComponent("i-search")!
  const _component_i_close = _resolveComponent("i-close")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["d-control-input d-control-input--appearance_bordered", {'d-control-input--search' : $setup.props.type === $setup.s}])
  }, [
    ($setup.props.description)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString($setup.props.description), 1))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("input", _mergeProps({ class: "d-control-input__element" }, _ctx.$attrs, {
      autocomplete: "off",
      type: $setup.inputType,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.val) = $event)),
      onInput: $setup.setValue
    }), null, 16, _hoisted_2), [
      [_vModelDynamic, $setup.val]
    ]),
    ($setup.isPasswordField)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "change-type pointer",
          onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.toggleType()))
        }, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(), _createBlock(_resolveDynamicComponent($setup.inputType === $setup.p ? 'IEye' : 'IEyeOff')))
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_i_key)
          ])
        ]))
      : _createCommentVNode("", true),
    ($setup.props.type === $setup.s)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_i_search)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "search-button",
      onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.search($setup.val)))
    }, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    ($setup.inputType === $setup.s && $setup.val)
      ? (_openBlock(), _createBlock(_component_i_close, {
          key: 3,
          class: "search-reset",
          onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.emit("reset:modelValue", null)))
        }))
      : _createCommentVNode("", true)
  ], 2))
}