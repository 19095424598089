

import { defineComponent, toRefs, ref, computed, watch, getCurrentInstance } from 'vue'
import { hasAccess } from "@aspectus/permissions";
// import { collectRoutePermissionCheckers } from "@aspectus/vue-router-permissions";
import { useRouter, useRoute } from 'vue-router'

export function collectRoutePermissionCheckers(route) {
  return route.matched.reduce((acc, r) => {
    const checker = r.meta.accessConfig && r.meta.accessConfig.checker;
    
    if (checker) {
      acc.push([checker, r]);
    }
    return acc;
  }, []);
}

export default defineComponent({
  name: "permissions-access-state",
  props: { to: {} },
  setup(props: any, ctx: any) {
    const { to } = toRefs(props);
    const router = useRouter()
    const route = useRoute()
    const instance = getCurrentInstance()
    const hasAccessToRoute: any = ref(false);

    const permissions = computed((): any => {
      return collectRoutePermissionCheckers(
        router.resolve(to.value)
      ).map((x) => x[0]);
    });

    const setAccess = (permissions) => {
      hasAccess(permissions || [], instance.appContext.config.globalProperties)
      .then((d) => {
        hasAccessToRoute.value = true;
      })
      .catch((e) => {
          hasAccessToRoute.value = false;
        });
    };

    watch(() => permissions, (nval) => {
      setAccess(permissions.value)
    }, { immediate: true })

    return { hasAccess: hasAccessToRoute, permissions, setAccess };
  },
});
